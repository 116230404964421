.hero-container {
  display: flex;
  width: 100%;
  height: 30rem;
  padding: 0rem 9.375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-shrink: 0;
  background-color: #036;
  @media (max-width: 768px) {
    height: 43.75rem;
    justify-content: space-between;
  }
}
.hero-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 9.38rem;
  padding-right: 9.38rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding-left: 0rem;
    padding-right: 0rem;
    justify-content: center;
  }
}
.hero-left-section {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.right-text {
  overflow: hidden;
  margin-left: 1.5rem;
  height: 3rem;
}

.right-text-content {
  display: flex;
  align-items: center;
  height: 3rem;
  animation: slide 10s infinite;
  animation-delay: 0s;
  @media (max-width: 1536px) {
    width: 20rem;
    height: 3rem;
  }
}
@keyframes slide {
  0%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-200%);
  }
  75% {
    transform: translateY(-205%);
  }
}
.vector-icon1 {
  width: 1.55363rem;
  height: 2rem;
  /* margin-bottom: 0.95rem; */
  margin-left: 1.5rem;
  @media (max-width: 1536px) {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.vector-icon2 {
  width: 2rem;
  height: 2rem;
  margin-left: 1.5rem;
  @media (max-width: 1536px) {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.vector-icon3 {
  width: 2rem;
  height: 2rem;
  /* margin-top: 1.16rem; */
  margin-left: 1.5rem;
  @media (max-width: 1536px) {
    width: 1.3rem;
    height: 1.3rem;
  }
}
.first-text {
  /* color: rgba(255, 255, 255, 0.3);
  font-size: 2rem; */
  color: #fff;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-bottom: 0.95rem; */
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: 500;
    width: max-content;
  }
  @media (max-width: 1536px) {
    font-size: 1.3rem;
    font-weight: 500;
    width: max-content;
  }
}
.second-text {
  color: #fff;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: 500;
    width: max-content;
  }
  @media (max-width: 1536px) {
    font-size: 1.3rem;
    font-weight: 500;
    width: max-content;
  }
}
.third-text {
  /* color: rgba(255, 255, 255, 0.3);
  font-size: 2rem; */
  color: #fff;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-top: 1.16rem; */
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: 500;
    width: max-content;
  }
  @media (max-width: 1536px) {
    font-size: 1.3rem;
    font-weight: 500;
    width: max-content;
  }
}
.data-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.data-label {
  color: #fff;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.25rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: 500;
    width: fit-content;
  }
  @media (max-width: 1536px) {
    font-size: 1.3rem;
    font-weight: 500;
    width: max-content;
  }
}
.ai-text {
  color: #0f0;
  font-family: Work Sans;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: 700;
  }
  @media (max-width: 1536px) {
    font-size: 1.3rem;
    font-weight: 500;
  }
}

.best-prices {
  color: #fff;
  font-family: Work Sans;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 1.5rem;
}
.hero-img {
  width: 18.75rem;
  height: 18.75rem;
  border-radius: 0.25rem;
}
.image-fader {
  width: 18.75rem;
  height: 18.75rem;
}
.image-fader img {
  position: absolute;
  top: 7rem;
  right: 9rem;
  animation-name: imagefade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  @media (max-width: 768px) {
    top: 20rem;
    right: 50%;
    transform: translateX(50%);
  }
}
@keyframes imagefade {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.image-fader img:nth-of-type(1) {
  animation-delay: 6s;
}
.image-fader img:nth-of-type(2) {
  animation-delay: 4s;
}
.image-fader img:nth-of-type(3) {
  animation-delay: 2s;
}
.image-fader img:nth-of-type(4) {
  animation-delay: 0;
}

.prices-button {
  display: flex;
  height: 2.9rem;
  padding: 0rem 1.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #0f0;
  margin-top: 1.5rem;
  cursor: pointer;
  @media (max-width: 768px) {
    width: max-content;
    margin-bottom: 1.88rem;
  }
}
.prices-button-text {
  color: #036;
  /* font-size: 0.9375rem; */
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.prices-button:hover {
  background-color: #fff;
  transition: all 0.3s;
}
