.process-container {
  width: 100%;
  height: 37.75rem;
  @media (max-width: 768px) {
    height: 34.5rem;
  }
}
.process-text {
  color: #036;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 9.38rem;
  @media (max-width: 768px) {
    margin-left: 0rem;
    margin-bottom: 2rem;
  }
}
.process-content {
  display: flex;
  padding-top: 6.25rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-top: 1.94rem;
  }
}
.process-right-section {
  margin-right: 9.38rem;
  display: flex;
  @media (max-width: 768px) {
    margin-right: 0rem;
  }
}
.circular-container {
  margin-right: 0.81rem;
  margin-top: 1.6875rem;
  @media (max-width: 768px) {
    margin-right: 2rem;
    margin-top: 2.44rem;
  }
}
.process-vertical-line {
  position: absolute;
  height: 17.9375rem;
  border-left: 0.03125rem dashed #036;
  margin-top: 4rem;
  margin-left: 1.3rem;
  z-index: -10;
}
.circular-ellipse {
  display: flex;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 60rem;
  background-color: #036;
  justify-content: center;
  align-items: center;
  margin-bottom: 7.06rem;
}
.process-number {
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.process-rectangle {
  width: 48.5rem;
  height: 5.9375rem;
  border-radius: 0.25rem;
  border: 0.5px solid #036;
  margin-bottom: 3.75rem;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 16.6875rem;
    height: 8.0625rem;
    margin-bottom: 1.31rem;
  }
}
.process-rectangle-icon {
  margin-left: 1.06rem;
  /* margin-top: 2.06rem; */
  @media (max-width: 768px) {
    display: none;
  }
}
.process-rectangle-rightText {
  margin-left: 1.44rem;
  @media (max-width: 768px) {
    margin-left: 1.06rem;
  }
}
.process-rectangle-rightText-Title {
  color: #036;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.process-rectangle-rightText-InfoText {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.44rem;
  padding-right: 17rem;
  @media (max-width: 768px) {
    width: 13.0625rem;
    height: 4.375rem;
    padding-right: 0rem;
  }
}
.process-rectangle-title-mobContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.process-rectangle-icon-mobile {
  display: none;
}
@media (max-width: 768px) {
  .process-rectangle-icon-mobile {
    display: block;
  }
}
