.Cta-container {
  width: 100%;
  background: #1f2228;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Cta-text {
  color: white;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding-top: 1.5rem;
}
.contact-button-router {
  text-decoration: none;
}
.contact-button {
  display: flex;
  height: 4rem;
  width: auto;
  padding: 0rem 1.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #0f0;
  margin-top: 4rem;
  margin-bottom: 3rem;
  cursor: pointer;
}
.contact-button-text {
  color: #036;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contact-button:hover {
  background-color: #fff;
  transition: all 0.3s;
}
