.navbar {
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  flex-direction: column;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  margin-left: 9.38rem;
}
.label {
  color: #000;
  padding-left: 0.5rem;
}
.me {
  color: #000;
  font-weight: 400;
}
.menu-section {
  display: flex;
  margin-right: 9.44rem;
  align-items: center;
  @media (max-width: 768px) {
    margin-right: 0rem;
    /* display: none; */
    width: 1rem;
  }
}
.menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  padding-top: 1.38rem;
  padding-bottom: 1.38rem;
  gap: 3.81rem;
  text-decoration: none;
  list-style-type: none;
  margin-right: 2rem;
  @media (max-width: 768px) {
    gap: 0rem;
    margin-right: 0rem;
  }
}

.menu-button {
  padding-right: 1.12rem;
  cursor: pointer;
  display: none;
}
.nav-links {
  text-decoration: none;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nav-links:hover {
  text-decoration: underline;
  text-decoration-color: #00ff00;
}
.button-router {
  text-decoration: none;
}
.nav-button {
  display: flex;
  height: 2.625rem;
  padding: 0rem 1.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #0f0;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0rem 0.7rem;
  }
}
.nav-button-text {
  color: #036;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
/* .nav-button:hover {
  background-color: #478a4a;
} */
@media (max-width: 1100px) {
  .menu-section {
    visibility: hidden;
  }
  .menu-button {
    display: block;
  }
  .navbar-logo {
    margin-left: 1.5rem;
  }
  .nav-button {
    margin-bottom: 1rem;
  }
}
.menu-section-mobile {
  display: flex;
  flex-direction: column;
}
.menu-items-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding-top: 4.12rem;
  padding-bottom: 1.38rem;
  gap: 2.75rem;
  text-decoration: none;
  list-style-type: none;
}
