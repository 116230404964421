.industrybanner-container {
  width: 100%;
  /* height: 9.0625rem; */
}
.banner-text {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 1.5rem;
  @media (max-width: 768px) {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}
.banner-logo-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2.94rem;
  margin-top: 2rem;
  margin-bottom: 2.94rem;
}
.banner-logo {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ibm-img {
  width: 5.5rem;
  height: 2.1875rem;
}
