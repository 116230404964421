.footer-container {
  display: flex;
  width: 100%;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  background: #000;
}
.footer-text {
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  @media (max-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
