.prices-container {
  width: 100%;
  /* height: 27.375rem; */
  height: auto;
  background-color: #f1f1f1;
}
.prices-text {
  color: #036;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
}
.prices-content {
  display: flex;
  flex-direction: row;
  padding-top: 6.25rem;
  justify-content: space-between;
  margin-left: 9.38rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    margin-left: 0rem;
  }
}
.prices-content-right {
  margin-right: 9.38rem;
  @media (max-width: 768px) {
    margin-right: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.prices-right-text {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1.5rem;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 1rem;
  }
}
.price {
  color: #036;
  font-weight: 700;
}
.table-container {
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border: 1px solid #036;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
}
.custom-table {
  border-collapse: collapse;
  width: 53.0625rem;
  height: 3.6875rem;
  background-color: rgba(0, 51, 102, 0.15);
  border-style: hidden;
}

.custom-table th,
.custom-table td {
  border: 1px solid #036;
  padding: 8px;
  text-align: center;
}
.custom-table th {
  color: #036;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.custom-table td {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1.75rem;
  padding-bottom: 1.69rem;
}
.second-row {
  background-color: white;
}
.second-row-first-col {
  background-color: #dfffdf;
}
.table-container-small {
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  border: 1px solid #036;
  overflow: hidden;
  width: 20.375rem;
  height: 14.75rem;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
.custom-table-small {
  border-collapse: collapse;
  width: 20.375rem;
  height: 14.75rem;
  border-style: hidden;
}
.custom-table-small th,
.custom-table-small td {
  border: 1px solid #036;
  /* padding: 8px; */
  text-align: center;
}
.custom-table-small-leftcolumn {
  color: #036;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: rgba(0, 51, 102, 0.15);
}
.custom-table-small-rightCol {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1.19rem;
  padding-bottom: 1.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.custom-table-small-rightCol-green {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1.19rem;
  padding-bottom: 1.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: rgba(0, 255, 0, 0.25);
}
.terms-conditions {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.terms-conditions-text {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    text-align: center;
  }
}
