.services-container {
  width: 100%;
  /* height: 36.375rem; */
  /* height: 57.4rem; */
  /* height: 39rem; */
  height: auto;
  background-color: #f1f1f1;
}
.services-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 2.75rem;
  align-items: center;
}
.services-text {
  color: #036;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vertical-line {
  width: 0.0625rem;
  height: 1.75rem;
  background-color: #000;
  margin: 0 10px;
  margin-left: 2.38rem;
  margin-right: 2.38rem;
}
.cards {
  margin-top: 2.69rem;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* width: 53.0625rem; */
  /* width: 35rem; */
  max-width: 71.3125rem;
  gap: 1.5rem;
  margin-bottom: 6.25rem;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1024px) {
    max-width: 35rem;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1280px) {
    max-width: 35rem;
    margin-bottom: 1.5rem;
  }
}
.card-box {
  /* width: 16.6875rem;
  height: 11.1875rem; */
  width: 16.6875rem;
  height: 13rem;
  border-radius: 0.25rem;
  background-color: #fff;

  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.card-box:hover,
.card-box:focus,
.card-box:active {
  transform: scale(1.03);
}
.card-box-img {
  width: 16.6875rem;
  height: 4.875rem;
}
.card-box-title {
  color: #036;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 0.5rem;
  padding-left: 1rem;
}
.card-box-info {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 0.7rem;
}
.bottom-layout {
  width: 100%;
  /* height: 33.6875rem; */
  height: auto;
  background-color: #00ff00;
}
.bottom-layout-title {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding-top: 4.38rem;
}
.bottom-layout-infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom-layout-infoText {
  color: #000;
  text-align: center;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding-top: 1rem;
  width: 71.8125rem;
  height: 4.25rem;
  @media (max-width: 768px) {
    width: 21.3125rem;
    height: 10.3125rem;
  }
}
.bottom-layout-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3.19rem;
  gap: 10.19rem;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
    gap: 2.62rem;
    margin-top: 9rem;
  }
}
.bottom-layout-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4.38rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
}
.bottom-layout-contentTitle {
  color: #036;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 1.25rem;
}
.bottom-layout-contentText {
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1rem;
  width: 13.5625rem;
  height: 4.25rem;
}
.learnMore-button {
  display: inline-flex;
  height: 2.625rem;
  padding: 0rem 1.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #fff;
  margin-top: 2rem;
  cursor: pointer;
}
.learnMore-button-text {
  color: #036;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.learnMore-button:hover {
  transform: translateY(-2px);
}
