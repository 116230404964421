.contactUs-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  /* height: 43.5625rem; */
  height: 100vh;
  padding: 0rem 9.375rem;
  align-items: center;
  background-color: #036;
  padding-top: 5.12rem;
}
.contactUs-text {
  color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.form-container {
  display: flex;
  margin-top: 1.5rem;
  padding: 0rem 9.375rem;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width: 768px) {
    padding: 0rem 0rem;
  }
}
.input-field {
  display: block;
  box-sizing: border-box;
  width: 47rem;
  height: 2.625rem;
  border-radius: 0.375rem;
  border: 1px solid #e2e2e2;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 1rem;
}

.message-field {
  width: 47rem;
  height: 6.8125rem;
  border-radius: 0.375rem;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 1rem;
  padding-top: 0.81rem;
  padding-right: 1rem;
}
@media (max-width: 768px) {
  .input-field {
    width: calc(100vw - 5rem);
    /* max-width: none; */
  }
  .message-field {
    width: 100%;
    max-width: none;
  }
  .contactUs-container {
    height: 100vh;
    padding-top: 3.12rem;
  }
}
.submit-btn {
  display: inline-flex;
  width: 6.5625rem;
  height: 2.625rem;
  padding: 0rem 1.625rem;
  align-items: center;
  background-color: #00ff00;
  border-radius: 0.25rem;
  color: #036;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}
.submit-btn:hover {
  background-color: #fff;
}
.error-alert {
  color: #bf1650;
}
.service-required {
  color: #fff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.radio-option-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    /* flex-wrap: wrap; */
    flex-direction: column;
    width: max-content;
    gap: 2.5rem;
  }
}
.radio-option1,
.radio-option2,
.radio-option3 {
  width: 5.8125rem;
  height: 2.625rem;
  padding-top: 0.69rem;
  padding-bottom: 0.69rem;
  border-radius: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border: 1px solid #e2e2e2;
  background-color: #e2e2e2;
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.radio-option-input {
  margin-right: 0.75rem;
}

input[type="radio"] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 1px solid #555555;
  border-radius: 50%;
  background-clip: content-box;
  background-color: white;
}

input[type="radio"]:checked {
  background-color: #00ff00;
  padding: 3px;
}
